import $ from "jquery";
import {active} from "./const.js";

$('#js-homeProblemButton').on('click', (event) => {
  let windowWidth = $(window).width();

  if(windowWidth <= 767){
    $(event.currentTarget).toggleClass(active);
    // $(event.currentTarget).children('.js-coverNavItemHeading').toggleClass(active);
    $('#js-homeProblemMenu').slideToggle();
  }

  return false;
});

$('.js-homeProblemLink').on('click', (event) => {
  $('body').css('overflow', 'hidden');
  let index = $('.js-homeProblemLink').index(event.currentTarget);
  $('.js-problemNav').eq(index).fadeIn();

  return false;
});

$('.js-problemNavCloseButton').on('click', (event) => {
  $('body').css('overflow', 'visible');
  $('.js-problemNav').fadeOut();

  return false;
});

$('.js-problemNav').on('click', (event) => {
  $('body').css('overflow', 'visible');
  $('.js-problemNav').fadeOut();
});
