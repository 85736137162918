import $ from "jquery";
import {active} from "./const.js";

let $element = $('#js-careerFixedNav');
let windowHeight = $(window).height();

function checkScrollPosition() {
  let scrollTop = $(window).scrollTop();
  let scrollBottom = $(document).height() - scrollTop - windowHeight;

    if (scrollTop >= 400 && scrollBottom >= 400) {
        $element.addClass(active);
    } else {
        $element.removeClass(active);
    }
}

$(window).on('scroll resize', function() {
    windowHeight = $(window).height();
    checkScrollPosition();
});

// 初期ロード時にもチェック
checkScrollPosition();
