import {active} from "./const.js";
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

var swiper = new Swiper("#js-recruitInterviewSwiper", {
  breakpoints: {
    768: {
      centeredSlides: false,
      slidesPerView: 4,
      spaceBetween: 16,
    },
  },
  centeredSlides: true,
  loop: true,
  navigation: {
    nextEl: "#js-recruitInterviewSwiper .swiper-button-next",
    prevEl: "#js-recruitInterviewSwiper .swiper-button-prev",
  },
  slidesPerView: "auto",
  spaceBetween: 0,
});
