import {active} from "./const.js";

document.addEventListener("DOMContentLoaded", () => {
  const elements = document.querySelectorAll('.js-explosion');
  const observerOptions = {
    root: null,
    rootMargin: '-50% 0px',
    threshold: 0
  };

  const observerCallback = (entries, observer) => {
    entries.forEach(entry => {
      if(entry.isIntersecting) {
        entry.target.classList.add(active);
      }
    });
  };

  const observer = new IntersectionObserver(observerCallback, observerOptions);

  elements.forEach(element => {
    observer.observe(element);
  });
});
