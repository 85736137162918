import $ from "jquery";
import {active} from "./const.js";

$('.js-navItemHeader').on('click', (event) => {
  let windowWidth = $(window).width();

  if(windowWidth <= 767){
    $(event.currentTarget).toggleClass(active);
    $(event.currentTarget).children('.js-navItemHeading').toggleClass(active);
    $(event.currentTarget).next('.js-navItemBody').slideToggle();
  }

  return false;
});
