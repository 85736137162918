import $ from "jquery";
import {active} from "./const.js";
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

let swiper = new Swiper("#js-recruitBgSwiper", {
  autoplay: {
    delay: 4000,
    disableOnInteraction: false,
  },
  effect: "fade",
  loop: true,
  spaceBetween: 0,
  speed: 2000,
});
