import {active} from "./const.js";
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

var swiper = new Swiper("#js-homeMvSwiper", {
  autoplay: {
    delay: 4000,
    disableOnInteraction: false,
  },
  breakpoints: {
    768: {
    },
  },
  effect: "fade",
  loop: true,
  pagination: {
    clickable: true,
    el: "#js-homeMvSwiper .swiper-pagination",
  },
  slidesPerView: 1,
  spaceBetween: 0,
  speed: 2000,
});
