document.addEventListener("DOMContentLoaded", function () {
  const videos = document.querySelectorAll(".js-newGradMovie");
  let currentSize = window.innerWidth;
  let device;

  function updateVideoSource(video, baseSrc, device) {
    const suffix = window.innerWidth <= 768 ? "md" : "lg";

    if(suffix != device) {
      const sourceMP4 = document.createElement("source");
      sourceMP4.setAttribute("src", baseSrc + "movie-" + suffix + ".mp4");
      sourceMP4.setAttribute("type", "video/mp4");

      video.innerHTML = "";
      video.appendChild(sourceMP4);
      video.setAttribute("class", suffix);
    }
  }

  videos.forEach((video) => {
    const baseSrc = video.getAttribute("data-src");
    device = "";
    updateVideoSource(video, baseSrc, device);
  });

  window.addEventListener("resize", function () {
    const newSize = window.innerWidth;
    if(currentSize !== newSize) {
      currentSize = newSize;
      videos.forEach((video) => {
        device = video.getAttribute("class");
        const baseSrc = video.getAttribute("data-src");
        updateVideoSource(video, baseSrc, device);
      });
    }
  });
});
