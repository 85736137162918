import {active} from "./const.js";
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

var swiper = new Swiper("#js-interviewSwiper", {
  centeredSlides: true,
  loop: true,
  navigation: {
    nextEl: "#js-interviewSwiper .swiper-button-next",
    prevEl: "#js-interviewSwiper .swiper-button-prev",
  },
  slidesPerView: 1,
  spaceBetween: 0,
});
