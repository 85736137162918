import {active} from "./const.js";
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

var swiper = new Swiper("#js-caseStudySwiper", {
  breakpoints: {
    768: {
      centeredSlides: false,
    },
  },
  centeredSlides: true,
  loop: true,
  navigation: {
    nextEl: "#js-caseStudySwiper .swiper-button-next",
    prevEl: "#js-caseStudySwiper .swiper-button-prev",
  },
  pagination: {
    clickable: true,
    el: "#js-caseStudySwiper .swiper-pagination",
  },
  slidesPerView: "auto",
  spaceBetween: 0,
});
