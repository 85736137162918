import $ from "jquery";
import {active} from "./const.js";

$('#js-coverNavButton').on('click', (event) => {
  $('body').css('overflow', 'hidden');
  $('#js-coverNav').fadeIn();

  return false;
});

$('#js-coverNavCloseButton').on('click', (event) => {
  $('body').css('overflow', 'visible');
  $('#js-coverNav').fadeOut();

  return false;
});

$('#js-coverNav a').on('click', (event) => {
  if($('body').attr('id') == 'js-home') {
    $('body').css('overflow', 'visible');
    $('#js-coverNav').fadeOut();
  }
});
