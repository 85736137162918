import $ from "jquery";
import {active} from "./const.js";

$(window).on('scroll', function() {
  let scroll = $(window).scrollTop();
  if(scroll >= 90) {
    $("#js-header").addClass(active);
  } else {
    $("#js-header").removeClass(active);
  }
});

// 固定ヘッダーの高さ分ずらす
$(window).on('load', function() {
  const url = $(location).attr('href'),
        headerHeight = $('#js-header').outerHeight();

  if(url.indexOf("#") != -1){
    const anchor = url.split("#"),
          target = $('#' + anchor[anchor.length - 1]),
          position = Math.floor(target.offset().top) - headerHeight;
		$("html, body").animate({scrollTop:position}, 10);
  }
});

